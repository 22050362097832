import { SearchPage } from 'Components'
import TemplateSearchPage from 'Stores/carbideanddiamondtooling/Templates/SearchPage.rt'
import TemplateMainContent from 'Stores/_default-store/Templates/MainContent.rt'
import { SearchHeader } from 'Components'
import { SearchResult } from 'Components'
import { FacetPanel } from 'Components'
import TemplateFacetPanelContainer from 'Stores/carbideanddiamondtooling/Templates/FacetPanelContainer.rt'
import { SearchBox } from 'Components'
import TemplateSearchBox from 'Stores/_default-store/Templates/SearchBox.rt'
import { SearchBoxDialogButton } from 'Components'
import TemplatesearchBoxdialogButtonMobile from 'Stores/_default-store/Templates/searchBox/dialogButtonMobile.rt'
import { FacetDialog } from 'Components'
import TemplateFacetDialog from 'Stores/_default-store/Templates/FacetDialog.rt'
import { SearchBoxDialog } from 'Components'
import TemplateSearchBoxDialog from 'Stores/_default-store/Templates/SearchBoxDialog.rt'

export const compMap = {
  SearchPage,
SearchHeader,
SearchResult,
FacetPanel,
SearchBox,
SearchBoxDialogButton,
FacetDialog,
SearchBoxDialog
} 
export const tplMap = {
  'SearchPage': TemplateSearchPage,
'MainContent': TemplateMainContent,
'FacetPanelContainer': TemplateFacetPanelContainer,
'SearchBox': TemplateSearchBox,
'searchBox/dialogButtonMobile': TemplatesearchBoxdialogButtonMobile,
'FacetDialog': TemplateFacetDialog,
'SearchBoxDialog': TemplateSearchBoxDialog
};