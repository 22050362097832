export default {
  PRODUCT_REPEATER_CLASS: 'product-items product-items-3',
  SHOW_RESULTS_CLASS: 'btn btn-default',
  BUTTON_PRIMARY_CLASS: 'button btn btn-primary',
  BUTTON_SECONDARY_CLASS: 'button btn btn-default',

  SEARCH_BOX_PLACEHOLDER: 'Search By Description Here',
  SEARCH_BOX_BUTTON_CLASS: 'btn btn-default btn-inverse',

  CURRENCY: 'USD',
};
